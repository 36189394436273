<template>

        <div class="login">
            <div class="loginl">
                <img style="width: 100%; height: 100%" src= "../../assets/images/logo.jpg"/>
            </div>
            <div class="loginr">
                <el-form :model="detailForm" ref="detailForm" label-width="100px" class="demo-userForm" :rules="rules">

                    <el-form-item label="用户名" prop="User_Account">
                        <el-input v-model="detailForm.User_Account"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="User_Password">
                        <el-input type="password" v-model="detailForm.User_Password"  @keyup.enter="submitForm('detailForm')"></el-input>
                    </el-form-item>

                    <el-form-item style="width:100%;">
                        <el-button type="primary" @click="submitForm('detailForm')" >登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    
          
        

    </template>
        <style>
 
        .login {
            position:absolute;
            width: 710px;
            height: 300px;
            top: 50%;
            left:50%;
            transform: translate(-50%,-50%);
        }

        .loginl {
            float: left;
            width: 300px;
            height: 300px;
        }

        .loginr {
            position: relative;
            float: left;
            width: 350px;
            height: 180px;
            top: 120px;

        }
    </style>
<script lang="ts">
import md5 from "js-md5";
    export default ({
            props: {
    obj: Object,
  },
data() {
                return {
                    UserL:{},
                    detailForm: {
                        User_Account: '',
                        User_Password: ''
                    },
                    rules: {
                        User_Account: [
                            { required: true, message: '请输入用户名', trigger: 'blur' }
                        ],
                        User_Password: [
                            { required: true, message: '请输入密码', trigger: 'change' }
                        ]
                    }
                };
            },
            created() {
            },
            methods: { 
         
                submitForm(formName) {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let data=JSON.parse(JSON.stringify(this.detailForm))
                            
// @ts-ignore
                            data.User_Password = md5(data.User_Password);
                            this.$axios.Post("login", data,"0","2",1).then(res => {
                                if (res.code == "200") {
                                    this.$notify({
                                        type: 'success',
                                        title: '成功',
                                        message: res.msg
                                    });
                                    this.UserL = {
                  User_ID: res.User_ID,
                  User_Account: res.User_Account,
                  Role_ID: res.Role_ID,
                  User_Name: res.User_Name,
                  Role_Name: res.Role_Name,
                  Dept_ID: res.Dept_ID,
                  Dept_Name: res.Dept_Name,
                  User_Portrait:res.User_Portrait,
                  Need_Change:res.Need_Change=="True",
                  User_Area: res.User_Area,
                };
                this.$gf.SetCookie("UserL", this.UserL, 1);
this.$store.state.UserL=this.UserL
                                }
                                else {
                                    this.$notify({
                                    type:'error',
                                    title: '错误',
                                        message: res.msg
                                    });
                                }

                            }).catch(error => {
                                this.$notify({
                                    type:'error',
                                    title: '错误',
                                    message: '请先登录'
                                });

                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }

            },
        })
        </script>