import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'

import naive from 'naive-ui'
import axios from './api/dbapi'
import globalVariable from './api/global_variable'
import globalFunction from './api/global_function'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import print  from 'vue3-print-nb'
// const req = require.context('./icons/svg', false, /\.svg$/) // 匹配当前目录下svg文件下的.svg结尾的文件
// //获取icons/svg目录下的所有svg文件,形成数组
// const reqs = req.keys().map(item => {
//   //获取文件名
//   const name = item.replace(/^\.\//, '').replace(/\.\w+$/, '')
//   //获取文件内容
//   const svg = req(item)
//   //返回对象
//   return {
//     name,
//     svg
//   }
// })
// req.keys().map(req) // 自动加载svg下面的文件
// //导入svg图标结束

const app=createApp(App)
app.use(ElementPlus, { locale })
app.use(naive)
app.use(store)
app.use(print)
// app.config.globalProperties.$icons = reqs;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$gv = globalVariable;
app.config.globalProperties.$gf = globalFunction;
// app.component('svg-icon', svgIcon)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(router)
app.mount('#app')
