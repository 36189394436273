import axios from 'axios'
import gv from './global_variable'
import gf from './global_function';
import store from '../store';
axios.defaults.withCredentials = true;

const isDevelopment = process.env.NODE_ENV !== "production";

const RunSql = async (str_Sql: string) => {
    let resu: [];
    await axios.post(gv.baseURL + "/post?m=runsql", { strSql: str_Sql }).then(res => {
        resu = JSON.parse(res.data);
    });
    return resu;
}

function GetBySql(str_Sql: string) {
    return (axios.post(gv.baseURL + "/post?m=getbysql", { strSql: str_Sql }))
}
const AddClass = async (id: string,tableName:string,data:any) => {
    let resu: any;
    console.log("tableName",tableName)
    console.log("id",id)
    console.log("data",data)
    await axios.post(gv.baseURL + "/post?m=addclass&a="+tableName+"&key="+id, data).then(res => {
        resu = JSON.parse(res.data);
    });
    return resu;
}

function GetClass(tableName: string, strWhere = "", iType = 0) {
    const strType = iType == 0 ? "list" : "detail";
    const data = {
        tableName: tableName,
        strWhere: strWhere
    }
    return axios.post(gv.baseURL + "/post?access_key=aaa&m=getclass&a=" + strType, data);
}

const LoadBySql = async (str_Sql: string, iType = 0) => {
    let resu: [];

    await GetBySql(str_Sql).then(res => {
        resu = (iType == 0 ? eval(JSON.parse(res.data).results) : eval(JSON.parse(res.data).results)[0]);
    });
    return resu;
}

const LoadClass = async (tableName: string, strWhere = "", iType = 0) => {
    let resu: [];
    await GetClass(tableName, strWhere, iType).then(res => {
        resu = iType == 0 ? eval(JSON.parse(res.data).results) : JSON.parse(JSON.parse(res.data).results);
    });
    return resu;
}
const PostUrl=async(m:string, a = '0', key = '0',iType=1)=> {
    let resu:[];
   await axios.post(gv.baseURL+"/post?m=" + m + "&a=" + a + "&key=" + key).then(res=>{

         resu= ( iType == 0?eval(JSON.parse(res.data).results):JSON.parse(res.data));
   });
   return resu;

}


const UpdateClass = async (tableName: string, key: string, data: any) => {
    let resu: [];
    await axios.post(gv.baseURL + "/post?a=" + tableName + "&key=" + key + "&m=updclass", data).then(res => {
        resu = JSON.parse(res.data);
    });
    return resu;
}

//统一用一种方法，
const Post = async (m: string, data: any = null, a = '0', key = '0', iType = 0, url = '') => {
    let resu: any;
    let err;
    if (url == '')
        url = gv.baseURL;
    await axios.post(url + "/post?access_key=aaa&m=" + m + "&a=" + a + "&key=" + key, data).then(res => {
        err = res;
        if (iType == 3)
            resu = res.data;
        else if (iType == 4)
            resu = eval(res.data);
        else
            resu = (iType == 0 ? eval(JSON.parse(res.data).results) : (iType == 1 ? JSON.parse(res.data) : eval(res.data)));

    }).catch(error => {
        console.log("error:" + error);
        console.log("m:" + m);
        console.log("a:" + a);
        console.log(err);
        console.log(data);
        resu = {
            code: 0,
            msg: err.data
        }
    });

    return resu;
}
///返回值为object，固定结构，
const Post2 = async (m: string, data: any = null, a = '0', key = '0', url = '') => {
    let resu = {
        code: 200,
        type: 'success',
        msg: '',
        results: ''
    };
    let err;
    if (url == '')
        url = gv.baseURL;
    await axios.post(url + "/post?access_key=aaa&m=" + m + "&a=" + a + "&key=" + key, data).then(res => {
        err = res;
        resu = JSON.parse(res.data);
    }).catch(error => {
        console.log("error:" + error);
        console.log("m:" + m);
        console.log("a:" + a);
        console.log(err);
        console.log(data);
        resu.code = 0;
        resu.msg = err.data
    });

    return resu;
}
function GetCategories(a: string) {
    return Post("getcategories", null, a, "0", 2).then((res: any[]) => {
        const o = [];
        for (let i = 0; i < res.length; i++) {
            if (res[i].C_Id != a) {//把第一个去掉
                o.push(res[i])
            }
        }
        return gf.ListToTreeList(o, "C_Id", "P_Id");
    })
}
function Upload(m: string, a = '0', f = '', r = 'date', t = '') {
    return (axios.post(gv.fileURL + "/upload?access_key=aaa&m=" + m + "&a=" + a + "&f=" + f + "&r=" + r + "&t=" + t))
}

const Delete = async (tableName: string, key: string, keyValue: string) => {
    let resu: [];
    const data = {
        tableName: tableName,
        key: key,
        keyValue: keyValue
    }
    await (axios.post(gv.baseURL + "/post?access_key=aaa&m=delete", data)).then(res => {
        resu = JSON.parse(res.data);
    });

    return resu;
}
function MoveFile(fromFile, toFile) {
    return (axios.post(gv.fileURL + "?m=move&f=" + fromFile + "&t=" + toFile))
}
function DeleteFile(filePath) {
    return (axios.post(gv.fileURL + "?m=delete&f=" + filePath))
}

const  GetModulePermissions= async(moduleid)=> {
    let resu: any;
    await (axios.post(gv.baseURL +"?key=" + moduleid + "&m=modulepremission&a="+store.state.UserL.Role_ID)).then(res => {
        resu= JSON.parse(res.data);
    })
    return JSON.parse(resu.results);
}
export default {
    RunSql,
// GetBySql,
AddClass,
    // GetClass,
    Delete,
    UpdateClass,
    Post,
    PostUrl,
    Post2,
    Upload,
    LoadClass,
    LoadBySql,
    MoveFile,
    DeleteFile,
    GetModulePermissions
}
