import moment from 'moment'
import store from "../store"
import gv from './global_variable'
import { defineAsyncComponent,markRaw } from "vue";
import {ElNotification, ElMessageBox} from 'element-plus';
import axios from './dbapi';
//线性插值
function Linterp(dblValues:number[][], dbl:number)
        {
            //如果第1行的第1个值大于第2行第1个值，说明反向，先反序
            if (dblValues.length > 1 && dblValues[0][0]  > dblValues[1][0])
            {
                const dblNew:any[] = [];
                for (let i = 0; i < dblValues.length; i++)
                {
                    for (let j = 0; j < dblValues[i].length; j++)
                        dblNew[i][j] = dblValues[dblValues.length - 1 - i, j];
                }
                dblValues = dblNew;
            }
            const iIndex = GetIndexMinus(dblValues, dbl)+1;
           const dblResults:any[] = [];//只要后面的几个值，不需要用于判断位置的值

            for (let i = 0; i < dblValues[0].length-1; i++)
            {
                if (dbl < dblValues[0][0] )
                    dblResults[i] = dblValues[0][i + 1];
                else if (dbl > dblValues[dblValues.length - 1][0])
                    dblResults[i] =dblValues[dblValues.length - 1][i + 1];
                else
                    dblResults[i] =dblValues[iIndex - 1][i + 1] + (dbl -dblValues[iIndex - 1][0]) / (dblValues[iIndex][0] - dblValues[iIndex - 1][0]) * (dblValues[iIndex][i + 1] - dblValues[iIndex - 1][i + 1]);
            }
            return dblResults;
        }

//获取当前日期
function GetDate(addDays = 0,format='YYYY-MM-DD'):string {
    const date1=new Date();
    const date2 =  date1.setDate(date1.getDate() + addDays);
    // const date = new Date(date2);
   return moment(date2).format(format);
}
//获取当前日期+时间
function GetDateTime(addDays = 0,format='YYYY-MM-DD HH:mm:ss'):string {
    const date1=new Date();
    const date2 =  date1.setDate(date1.getDate() + addDays);
    const date = new Date(date2);
    return moment(date).format(format);
}
function ShowDate(str:string) {
    let dd=''+str;
    if (dd == '/Date(-62135596800000)/') return '';
if(dd.indexOf('Date')==-1)
dd='Date('+dd+")";
    const date = eval('new ' + dd.replaceAll('/','')); //new Date()
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

function GetItemByKey(data:[], key:string, keyValue:string, resultKey=null) {
  if(data==undefined){
    console.log("data key",key)
  }else{
    for (let i = 0; i < data.length; i++) {
      if (data[i][key] == keyValue) {
          if(resultKey==null)
          return data[i];
          else
          return data[i][resultKey];
      }
  }
  }
  
  
}

function GetFDate(date:any,format='YYYY-MM-DD'):string{
   return moment(date).format(format);

}
function GetFTime(date:any,format='YYYY-MM-DD HH:mm:ss'):string{
    return moment(date).format(format);

 }
function ListToTreeList(list:{ [key: string]: any }[],idField:string,pidField:string) { // 将普通列表转换为树结构的列表
   if (!list || !list.length) {
       return []
   }
   const treeListMap :{ [key: string]: any } ={};
   let item: { [key: string]: any } = {};
   for (item  of list) {
       treeListMap[item[idField]] = item
   }
   for (let i = 0; i < list.length; i++) {
       if (list[i][pidField] && treeListMap[list[i][pidField]]) {
           if (!treeListMap[list[i][pidField]].children) {
               treeListMap[list[i][pidField]].children = []
           }
           treeListMap[list[i][pidField]].children.push(list[i]);
           list.splice(i, 1);
           i--
       }
   }
   return list
}
function ListToTreeListExt(list, idField, nameField,levelField,pnameField) { // 将普通列表转换为树结构的列表
  if (!list || !list.length) {
      return []
  }

  let treeListMap1 = {};//第一层
  let treeListMap2 = {};//第二层
  let treeListMap3 = {};//第三层

  let keyListMap1 = {};//第一层
  let keyListMap2 = {};//第二层
  let keyListMap3 = {};//第三层

  let keyMap = {};//第一层

  let list1 = [];
  let list2 = [];
  let list3 = [];

  for (let item of list) {
      if (item[levelField] == 1) {//一层
          keyListMap1[item[idField]] = item;
          keyMap[item[idField]] = item[nameField];
          treeListMap1[item[nameField]] = item;
          list1.push(item);

      }
      else if (item[levelField] == 2) {//二层
          keyListMap2[item[idField]] = item;
          keyMap[item[idField]] = item[nameField];
          treeListMap2[item[nameField]] = item;
          list2.push(item);
      }
      else if (item[levelField] == 3) {//三层
          keyListMap3[item[idField]] = item;
          keyMap[item[idField]] = item[nameField];
          treeListMap3[item[nameField]] = item;
          list3.push(item);
      }
      else { }
  }//for (let item of list)

  list.splice(0, list.length);

  if (list3.length > 0) {
      for (let i = 0; i < list3.length; i++) {
          if (!keyListMap2[list3[i][pnameField]].children) {
              keyListMap2[list3[i][pnameField]].children = []
          }
          keyListMap2[list3[i][pnameField]].children.push(list3[i]);
      }//for (let i = 0; i < list.length; i++)
  }

  if (list2.length > 0) {
      for (let i = 0; i < list2.length; i++) {
if(!keyListMap1[list2[i][pnameField]])
    keyListMap1[list2[i][pnameField]]=[];
          if (!keyListMap1[list2[i][pnameField]].children) {
              keyListMap1[list2[i][pnameField]].children = [];
              //treeListMap1[keyMap[list2[i][pnameField]]].children = [];
          }           
          keyListMap1[list2[i][pnameField]].children.push(keyListMap2[list2[i][idField]]);
          //treeListMap1[keyMap[list2[i][pnameField]]].children.push(keyListMap2[list2[i][idField]]);            
      }//for (let i = 0; i < list.length; i++)        
  }
  //list = treeListMap1;
  for (let i = 0; i < list1.length; i++){
      list.push(keyListMap1[list1[i][idField]]);
  }
  return list;
}

function ListToTreeListCommon(list, parentId) {
  if (!list || !list.length) {
      return [];
  }//if (!list || !list.length)
  let treeListMap = {};
  let treeList = [];
  let rootArray = [];
  list.forEach(item => {
      if (item.parentid == parentId) {
          rootArray.push(item);
      }
  })
  for (let item of rootArray) {
      var treejson = GetTree(item.id, list);
      if (treejson.length>0) {
          item.children = treejson;
      }        
      //treeList.push(treejson);
  }

  //treeListMap = listToJsonTree(list, parentId);
  return rootArray;
}//listToTreeListCommon(list, parentId) 

function GetTree(id, array) { // 获取客户分类的树结构
  let samelevel = GetSameLevel(id, array) // 获取同级数组
  if (samelevel.length > 0) {
      samelevel.forEach(item => {

          var result = GetTree(item.id, array);
          if (result.length>0) {
              item.children = result;
          }            
      })
  }
  return samelevel;
}

function GetSameLevel(id, array) { // 获取同级数组,id: 当前父级id，也就是要找子节点的parent_id == id, array： 原数组
    let newArray = []
    array.forEach(item => {
        if (item.parentid == id) {
            newArray.push(item)
        }
    })
    return newArray;
}

function moneyFormat(row, column){
    var ret = row[column.property];
    return ret?( ret.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')):0
}
function dateFormat(row, column) {
    var date = row[column.property];
    let ret=GetFDate(date);
    if(!ret.startsWith("20"))
        ret="2022-02-02"
    return ret;
}
//获取UserL Cookie
function GetUserLCookie() {
    const UserL=
    {
        User_ID: GetSubCookie("UserL", "User_ID"),
        User_Name: GetSubCookie("UserL", "User_Name"),
        Role_ID: GetSubCookie("UserL", "Role_ID"),
        Role_Name: GetSubCookie("UserL", "Role_Name"),
        User_Account: GetSubCookie("UserL", "User_Account"),
        Dept_ID: GetSubCookie("UserL", "Dept_ID"),
        Dept_Name: GetSubCookie("UserL", "Dept_Name"),
        User_Portrait: GetSubCookie("UserL", "User_Portrait"),
        Need_Change: GetSubCookie("UserL", "Need_Change").toLowerCase()=='true',
    }
    return UserL;
 }

//获取单值cookie
function GetCookie(name:string):string {
   const arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
   if (arr != null)
    return decodeURI(arr[2]);
   else
   return "";
}
// 获取多值cookie的子cookie
function GetSubCookie(name:string, subName:string):string {
      const all = GetCookie(name).split('&');
      for (let i = 0; i < all.length; i++) {
          if (subName == all[i].split('=')[0]) {
              return all[i].split('=')[1];
          }
      }
   return "";
}
function SetCookie(name:string, subCookies:any, days:number) {
   const d= new Date();
    d.setTime(d.getTime() + 24*60*60*1000*days);

           const cookieText = ";" + encodeURIComponent(name) + "=",
          arr :any[]= [];
          if(subCookies!=null)
          for(const attr in subCookies) {
              arr.push([encodeURIComponent(attr)] + "=" + encodeURIComponent(subCookies[attr]));
         }
         window.document.cookie= name+"="+ arr.join('&')+";path=/;expires="+d.toUTCString();
       }
function DeleteCookie(name:string) {
        SetCookie(name, null, 0);
      }
function DisableInData2(data1, data2, key) {

        for (let i = 0; i < data1.length; i++) {
            data1[i]["disabled"] = false;
            for (let j = 0; j < data2.length; j++) {
                if (data1[i][key] == data2[j][key]) {
                    data1[i]["disabled"] = true;
                }
            }
        }
        return data1;
    }
function CheckICNumber(icnumber:string) {
        if (icnumber.length == 18) {
            const t = icnumber.split("");
            const b = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            let total = 0;
            for (let i = 0; i < b.length; i++) {
                total += Number(t[i]) * b[i];
            }
            const ch =  total % 11;
            const index = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"]
            return (t[17] == index[ch]);
        }
    }
    function GetSex(icnumber:string) {
        if (icnumber.length == 18) {
            const t = icnumber.split("");
            return !(Number(t[14]) % 2 == 0)

        }
    }

    function GetIndexMinus(iValues:number[][],  iValue:number):number
    {
        let iThis = -1;
        for (let j0 = 1; j0 < iValues.length; j0++)
            if (iValue >= iValues[j0 - 1][0] && iValue <= iValues[j0][0])
                iThis = j0;
        return iThis == -1 ? iValues.length - 2 : iThis - 1;
    }
   function GetOrderedNumbers(dblOri:[], iDirection=1){
    dblOri.sort(function(a,b){return iDirection>0?(a-b):(b-a)});
    return dblOri
   }


    //#region Tab方法
   function GetTabInfo(targetName: string,comUrl: string,obj: any = {}){
        //comUrl后面允许用?带参数，暂时限一个id
        if(!obj)
            obj={};
             let url = comUrl.split("?")[0].split('.')[0];//处理是为了保持数据库中的html路径不变，让旧系统可以运行
             if(url.startsWith('/'))
              url=url.substring(1)
             if(comUrl.indexOf("?")>0){
              const all=comUrl.split("?")[1].split('&');//所有参数，id=3&pa=2
              for(let i=0;i<all.length;i++){
                obj[all[i].split('=')[0]]=all[i].split('=')[1]
              }
             } 
             const apps =markRaw( defineAsyncComponent(
               () => import("../views/" + url + ".vue")
             ) );
            //    this.obj = obj;

             return{
                title: targetName,
                 name: targetName,
                 url: comUrl,
                 ref: targetName,
                 closable: true,
                 content: apps,
                 obj: obj,
             }
           }
          function OpenInTabs(targetName: string,comUrl: string,obj: any = null) {
       const targetTab=GetTabInfo(targetName,comUrl,obj);
             let need = true;
             store.state.allTabs.forEach((tab:any, index) => {
               if (tab.name === targetName) {
                 need = false;
               }
             });
             if (need) {
               store.state.allTabs.push(targetTab);
               // zyf 2023/02/28
              //  const th = JSON.parse(localStorage.getItem('deal3_allTabs'))
              //  if(th){
              //   th.push(targetTab);
              //   localStorage.setItem('deal3_allTabs', JSON.stringify(th))
              //  }

             }
             ActiveTab(targetName);
             store.state.currentTab = targetName;
           }
          function ActiveTab(targetName: string) {
            store.state.currentTab = targetName;
          }
          function ReLoadTab() {
            //从editableTabs中找到当前tab
                  // var tab = this.editableTabs.find(
                  //   (item) => item.name == this.editableTabsValue
                  // );
                  let nn = 0;
                  let tab = null;
                  for (let i = 0; i < store.state.allTabs.length; i++) {
                    if (store.state.allTabs[i].name == store.state.currentTab) {
                      nn = i;
                      tab = store.state.allTabs[i];
                      break;
                    }
                  }
                  if(tab!=null){
                   const cc=GetTabInfo(tab.name,tab.url,tab.obj);
                   store.state.allTabs.splice(nn, 1, cc);

                   // zyf 2023/02/28
                  //  const th = JSON.parse(localStorage.getItem('deal3_allTabs'))
                  //  if(th){
                  //   th.splice(nn, 1, cc);
                  //   localStorage.setItem('deal3_allTabs', JSON.stringify(th))
                  //  }
                  }

                }
                function CloseTab(tableName:string) {
                        let nn = 0;
                        let tab = null;
                        for (let i = 0; i < store.state.allTabs.length; i++) {
                          if (store.state.allTabs[i].name == tableName) {
                            nn = i;
                            tab = store.state.allTabs[i];
                            break;
                          }
                        }
                        if(tab!=null){
                         store.state.allTabs.splice(nn, 1);      
                         // zyf 2023/02/28
                        //  const th = JSON.parse(localStorage.getItem('deal3_allTabs'))
                        //  if(th){
                        //   th.splice(nn, 1);
                        //   localStorage.setItem('deal3_allTabs', JSON.stringify(th))
                        //  }
                        }
      
                      }
          /**
      `       * getArryMegre->将数组中相同对象合并
            * data ->需要处理的数组
            * keyValue ->相同参数的关键值
            */
            function getArryMegre(data, keyValue) {
              const keySet = new Set(data.map(item => item[keyValue]))
              return Array.from(keySet).map(v => {
                return {
                  key: v,
                  data: data.filter(item => item[keyValue] === v)
                }
              })
            }
          //#endregion



//#endregion
function DownloadFile(filePath, fileName='') {
  let file=  (fileName==''? filePath.substring(filePath.replace(/\//g,'\\').lastIndexOf("\\")+1).replace(new RegExp(/( )/g),"_"):fileName);
  if(file.indexOf('/')>-1){
    file = file.substring(file.replace(/\//g,'\\').lastIndexOf("\\")+1).replace(new RegExp(/( )/g),"_")
  }
  console.log(gv.bUrl+ filePath)
  fetch(gv.bUrl+ filePath).then(async res => await res.blob()).then((blob) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = URL.createObjectURL(blob);
    a.download = file;
    a.click();
  })
     
}


export default {
    GetDate,
    GetDateTime,
    GetFDate,
    GetFTime,
    ShowDate,
    GetItemByKey,
    ListToTreeList,
    ListToTreeListExt,
    ListToTreeListCommon,
    GetTree,
    moneyFormat,
    dateFormat,
    GetCookie,
    GetSubCookie,
    SetCookie,
    GetUserLCookie,
    DeleteCookie,
    DisableInData2,
    CheckICNumber,
    GetSex,
    GetIndexMinus,
    Linterp,
    GetOrderedNumbers,
    OpenInTabs,
    GetTabInfo,
    ReLoadTab,
    CloseTab,
    getArryMegre,
    DownloadFile,


  }
