import { createStore } from 'vuex'
const state={
  allTabs: [],
  currentTab: {},
  UserL:{User_ID:0,Role_ID:0},
}
const store= createStore({
  state,
  
}) 
export default store;

// export default createStore({
//   state: {
//   allTabs: [],
//   currentTab: {},
//   UserL:{},
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
