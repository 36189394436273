<template>
  <n-config-provider>
 <n-loading-bar-provider>
 <!-- <n-notification-provider> -->
 <!-- <component :is="aaaa" :msg=str ></component> -->
 <Home v-if="this.$store.state.UserL && this.$store.state.UserL.Role_ID>0" ref="home" />
  <Login v-else />
 <!-- <component :is="this.$store.state.homePage" v-if="this.$store.state.UserL!=null" ></component>
 <Login v-else />  -->
 <!-- <login @returnLogin=getLogin> </login> -->
 <!-- </n-notification-provider> -->
 </n-loading-bar-provider>
 </n-config-provider>
 </template>
 
 <style lang="scss">
 .mt{
  margin-top:45px;
 }
 svg{
  width: 20px;
  height: 20px;
 }
.el-table .el-icon{
  color:blue !important
}
 @media print{
  .el-table{
.el-table__header{
width:100%!important;
}
.el-table__body{
width:100%!important;
}
th{
display: table-cell !important;
}
.cell{
width:100%!important;
}
}
.el-table th.gutter{
  display: table-cell !important;
}
.el-table colgroup.gutter{
  display: table-cell !important;
}
 }
.el-table th.gutter{
  display: table-cell !important;
}
.el-table colgroup.gutter{
  display: table-cell !important;
}
 .bd{
   border: 2px dashed darkcyan;
 }
 .el-table .warning-row {
    background: oldlace;
}

.el-table .success-row {
    background: #f0f9eb;
}
u{
    color:blue;
    cursor:pointer;
}
.el-form .el-input {
    width: 200px;
}
.el-form .el-select {
    width: 200px;
}
 </style>
 <script lang="ts">
 import { defineComponent,defineAsyncComponent ,markRaw } from 'vue'
 import Home from './views/Home.vue'
 import Login from './views/System/Login.vue'
 //#region 防止使用同组件的标签切换时报错
 const debounce = (fn, delay) => {
	let timer = null;
	return function () {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
};
//#endregion
 export default defineComponent({
   name: 'App',
    data() {
                 return {
                  _ResizeObserver :window.ResizeObserver,
                   UserL:{},
                     // homePage:{},
                 }
 
             },
 
             created() {
               this.$store.state.UserL=null;
                     // this.homePage= Home;
                     // this.aaaa=HelloWorld;
  this.GetUserInfo();
 
 
 
             },
             methods: { 

 GetUserInfo(){
   if (this.$gf.GetCookie("UserL") != "") {
                     this.$store.state.UserL=this.$gf.GetUserLCookie();
                     // this.$store.state.homePage= Home;
 
                 }
                 else {
                   this.$store.state.UserL=null;
                         this.$message.info("请先登录");
                         // this.$store.state.homePage= Login;
 
                 }
                     
 },
 
   
 
             },
             components:{
                 Home:markRaw(Home),
                 Login:markRaw(Login)
             }
 })
 </script>
 
 <!-- <style lang="scss">
 
 .n-menu1 {
   --item-text-color-child-active: rgb(243, 255, 80) !important;
   --item-icon-color-child-active:rgb(243, 255, 80) !important;
   --item-text-color-hover: rgb(243, 255, 80) !important;
     --item-icon-color-hover: rgb(243, 255, 80) !important;
     --item-text-color-active: rgb(243, 255, 80) !important;
     --item-icon-color-active: rgb(243, 255, 80) !important;
     --arrow-color-hover: rgb(243, 255, 80) !important;
     --arrow-color-active: rgb(243, 255, 80) !important;
     --arrow-color-child-active: rgb(243, 255, 80) !important;
     --item-color-active: rgba(243, 255, 80, 0.1) !important;
 
     --n-item-text-color-child-active: rgb(243, 255, 80) !important;
     --n-item-icon-color-child-active:rgb(243, 255, 80) !important;
     --n-item-text-color-hover: rgb(243, 255, 80) !important;
     --n-item-icon-color-hover: rgb(243, 255, 80) !important;
     --n-item-text-color-active: rgb(243, 255, 80) !important;
     --n-item-icon-color-active: rgb(243, 255, 80) !important;
     --n-arrow-color-hover: rgb(243, 255, 80) !important;
     --n-arrow-color-active: rgb(243, 255, 80) !important;
     --n-arrow-color-child-active: rgb(243, 255, 80) !important;
     --n-item-color-active: rgba(243, 255, 80, 0.1) !important;
     --n-arrow-color-active-hover: rgb(243, 255, 80, 0.1) !important;
     --n-arrow-color-child-active: rgb(243, 255, 80, 0.1) !important;
     --n-arrow-color-child-active-hover: rgb(243, 255, 80, 0.1) !important;
     --n-item-color-hover: rgb(243, 255, 80, 0.1) !important;
     --n-item-color-active: rgb(243, 255, 80, 0.1) !important;
     --n-item-color-active-hover: rgb(243, 255, 80, 0.1) !important;
     --n-item-text-color-child-active-hover: rgb(243, 255, 80) !important;
     --n-item-text-color-active-hover: rgb(243, 255, 80) !important;
     --n-item-icon-color-active-hover: rgb(243, 255, 80) !important;
     --n-item-icon-color-child-active-hover: rgb(243, 255, 80) !important;
 }
 
 </style> -->
 