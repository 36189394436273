const isDevelopment = process.env.NODE_ENV !== "production";
let bUrl = "";
if (isDevelopment) {
  bUrl = "http://localhost:44317/"; //本地
    // bUrl = '/url/'//本地连接服务器上的后台
} else {
bUrl = ""; //用于网页版
}
const baseURL =bUrl + 'api/Basic'
const fileURL =bUrl+ 'api/File/Upload'
// const downlodUrl =bUrl+ 'api/File/Upload'
const contentURL =bUrl+'content'
const binURL =bUrl+'bin'
const token = '123456'
export default {
  isDevelopment,
  bUrl,
  baseURL,
  fileURL,
  binURL,
  contentURL,
  token,
}
