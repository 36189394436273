import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_Home = _resolveComponent("Home", true)!
  const _component_n_notification_provider = _resolveComponent("n-notification-provider")!
  const _component_n_loading_bar_provider = _resolveComponent("n-loading-bar-provider")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, null, {
    default: _withCtx(() => [
      _createVNode(_component_n_loading_bar_provider, null, {
        default: _withCtx(() => [
          _createVNode(_component_n_notification_provider, null, {
            default: _withCtx(() => [
              _createTextVNode(" adfsf " + _toDisplayString(this.$store.state.UserL.User_ID) + " ", 1),
              (this.$store.state.UserL == null)
                ? (_openBlock(), _createBlock(_component_Login, { key: 0 }))
                : (_openBlock(), _createBlock(_component_Home, {
                    key: 1,
                    ref: "home"
                  }, null, 512))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}