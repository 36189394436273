<template>
    <n-config-provider>
        <n-loading-bar-provider>
            <n-notification-provider>
                adfsf
                {{ this.$store.state.UserL.User_ID }}
                <!-- <component :is="aaaa" :msg=str ></component> -->
                <Login v-if="this.$store.state.UserL == null" />
                <Home v-else ref="home" />
                <!-- <component :is="this.$store.state.homePage" v-if="this.$store.state.UserL!=null" ></component>
   <Login v-else />  -->
                <!-- <login @returnLogin=getLogin> </login> -->
            </n-notification-provider>
        </n-loading-bar-provider>
    </n-config-provider>
</template>

<style>
.bd {
    border: 2px dashed darkcyan;
}
</style>

<script lang="ts">
import { defineComponent, defineAsyncComponent, markRaw } from 'vue'
import Home from '../views/Home.vue'
import Login from '../views/System/Login.vue'

export default defineComponent({
    name: 'App',
    data() {
        return {
            UserL: {},
            // homePage:{},
        }

    },

    created() {
        this.$store.state.UserL = null;
        // this.homePage= Home;
        // this.aaaa=HelloWorld;
        this.GetUserInfo();
        localStorage.setItem('vueuse-color-scheme', 'auto')



    },
    methods: { 
        GetUserInfo() {
            
                if (this.$gf.GetCookie("UserL") != "") {
                    this.$store.state.UserL = this.$gf.GetUserLCookie();
                    // this.$store.state.homePage= Home;

                }
                else {
                    this.$store.state.UserL = null;
                    this.$message.info("请先登录!");
                    // this.$store.state.homePage= Login;

                }
            
        },



    },
    components: {
        Home: markRaw(Home),
        Login: markRaw(Login)
    }
})
</script>

<style lang="scss">
.n-menu1 {
    --item-text-color-child-active: rgb(243, 255, 80) !important;
    --item-icon-color-child-active: rgb(243, 255, 80) !important;
    --item-text-color-hover: rgb(243, 255, 80) !important;
    --item-icon-color-hover: rgb(243, 255, 80) !important;
    --item-text-color-active: rgb(243, 255, 80) !important;
    --item-icon-color-active: rgb(243, 255, 80) !important;
    --arrow-color-hover: rgb(243, 255, 80) !important;
    --arrow-color-active: rgb(243, 255, 80) !important;
    --arrow-color-child-active: rgb(243, 255, 80) !important;
    --item-color-active: rgba(243, 255, 80, 0.1) !important;

    --n-item-text-color-child-active: rgb(243, 255, 80) !important;
    --n-item-icon-color-child-active: rgb(243, 255, 80) !important;
    --n-item-text-color-hover: rgb(243, 255, 80) !important;
    --n-item-icon-color-hover: rgb(243, 255, 80) !important;
    --n-item-text-color-active: rgb(243, 255, 80) !important;
    --n-item-icon-color-active: rgb(243, 255, 80) !important;
    --n-arrow-color-hover: rgb(243, 255, 80) !important;
    --n-arrow-color-active: rgb(243, 255, 80) !important;
    --n-arrow-color-child-active: rgb(243, 255, 80) !important;
    --n-item-color-active: rgba(243, 255, 80, 0.1) !important;
    --n-arrow-color-active-hover: rgb(243, 255, 80, 0.1) !important;
    --n-arrow-color-child-active: rgb(243, 255, 80, 0.1) !important;
    --n-arrow-color-child-active-hover: rgb(243, 255, 80, 0.1) !important;
    --n-item-color-hover: rgb(243, 255, 80, 0.1) !important;
    --n-item-color-active: rgb(243, 255, 80, 0.1) !important;
    --n-item-color-active-hover: rgb(243, 255, 80, 0.1) !important;
    --n-item-text-color-child-active-hover: rgb(243, 255, 80) !important;
    --n-item-text-color-active-hover: rgb(243, 255, 80) !important;
    --n-item-icon-color-active-hover: rgb(243, 255, 80) !important;
    --n-item-icon-color-child-active-hover: rgb(243, 255, 80) !important;
}
</style>
