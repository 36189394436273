<template>
  <vue3-intro-step v-model:show="showIntro" :config="config" />
  <el-container>
    <el-header class="header" style="-webkit-app-region: drag;-webkit-user-select: none;display: inline;">
      <n-space justify="space-between">
      <div  style="height: 50px; margin: 5px; float: left">
        <span style="vertical-align: top; margin: 0px;width:450px;"><img class="logo" src="../assets/images/logonew.png" /></span>
        <div class="headtext">项目管理系统</div>
      </div>
      <n-dropdown trigger="hover" :options="options" :render-icon="renderDropdownIcon" @select="handleSelect" >
            <span style="font-size: 15px; color: #fff;line-height:60px;margin-right: 10px;">
              <n-avatar size="small" round :src="this.$gv.bUrl+'FileUpload/Portrait/'+this.$store.state.UserL.User_Portrait" style="vertical-align: middle; margin-right: 5px" />
              {{ this.$store.state.UserL.User_Name }}<span style="font-size:10px;color:yellow">({{this.$store.state.UserL.Role_Name}})</span>
            </span>
          </n-dropdown>
        </n-space>
    </el-header>
    <el-container class="iframe">
      <n-layout has-sider>
        <n-layout-sider bordered collapse-mode="width" :collapsed-width="0" :width="240" :collapsed="collapsed"
          show-trigger="button" style="position:relative;z-index:1000" @update:collapsed="handleCollapse" id="step5">
          <n-scrollbar>
            <n-menu class="menu0" :default-expanded-keys="defaultExpandKey" :collapsed="collapsed" :collapsed-width="64"
              :collapsed-icon-size="22" :options="menu" :render-icon="renderMenuIcon" @update:value="OpenMenu"
              key-field="MOD_Code" :label-field="'MOD_Name'" />
          </n-scrollbar>
          <!-- <div class="aside" id="div_aSide">
                        <div style="font-size:20px;text-align:center;cursor:pointer;" @click="FoldMenu"><i class="el-icon-s-fold" v-if="!isCollapse"></i><i class="el-icon-s-unfold" v-if="isCollapse"></i></div>
                        <div>
                            <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse" background-color="#42485b" text-color="#fff" active-text-color="#ffd04b"
                                     v-for="item in menu" :key="item">
                                <el-submenu :index="item.MOD_Code">
                                    <template #title>
                                        <i :class="'el-icon-'+item.MOD_PictureName"></i>
                                        <span>{{item.MOD_Name}}
                            </span>
                                    </template>
                                    <el-menu-item v-for="subitem in item.children" :key="subitem" :index="subitem.MOD_Code" @click="OpenInTabs(subitem.MOD_Name,subitem.MOD_Url+subitem.MOD_File)"> <i :class="'el-icon-'+subitem.MOD_PictureName"></i>{{subitem.MOD_Name}}</el-menu-item>
                                </el-submenu>
                            </el-menu>
                        </div>
                    </div>     -->
        </n-layout-sider>
        <n-layout>
          <div class="tabs-container">
          <div class="tabDiv">
            <n-tabs v-model:value="this.$store.state.currentTab"   type="card" closable @close="removeTab" 
              tab-style="min-width: 80px;">
              <n-tab-pane v-for="item in this.$store.state.allTabs" :key="item.name" :name="item.name"
                :closable="item.closable" display-directive="show">
                <div
                  :id="`main-charts_${this.$store.state.allTabs.findIndex(items => item.name === items.name && item.name === this.$store.state.currentTab)}`">
                  <component :is="item.content" :ref="item.ref" :obj="item.obj"></component>
                </div>
              </n-tab-pane>

            </n-tabs>
            <div class="tabBtns">
            <n-space>
        
          <n-popover>
            <template #trigger>
              <n-button type="warning" text size="small"  @click="this.$gf.ReLoadTab" >
                <RefreshLeft  />
          </n-button> 
            </template>
           刷新当前页面
          </n-popover>
          <n-popover>
            <template #trigger>
              <n-button type="error" text size="small"  @click="this.CloseAllTabs" >
            <Close  />
          </n-button>
            </template>
           关闭所有页面
          </n-popover>
      
        </n-space>
            </div>
          </div>
        </div>
        </n-layout>
      </n-layout>
    </el-container>
  </el-container>
</template>
<script lang="ts">
import { h, defineComponent, defineAsyncComponent, Component, ref, markRaw, watch, onUnmounted } from "vue";
import type { MenuOption } from 'naive-ui'
// const flag = window && window.process && window.process.versions && window.process.versions['electron'];
// var remote;if(flag) remote= window.require("@electron/remote/main");//原方式require("electron").remote;
import { useLoadingBar } from 'naive-ui'
// import { useNotification } from 'naive-ui'
import { ElMessageBox } from 'element-plus'
import type { DropdownOption } from 'naive-ui'
import { useRouter } from 'vue-router' //引入useRouter
import { useStore } from 'vuex';
import axios from "@/api/dbapi";
import moment from 'moment';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

export default defineComponent({
  name: "Home",
  data() {
    return {
      defaultExpandKey: ['100100'],
      fullscreen: false,
      collapsed:false,
      obj: {},
      currentTabComponent: "",
      imageUrl: "",
      menu: [],
      //postUrl: '../API/FileAPI.aspx?m=Portrait&r=guid',
      isCollapse: false,
      menuList: [],
      dialogVisible: false,
      tableData: "",
      search: "",
      roleList: [],
      editableTabsValue: "",
      editableTabs: [],
      defaultTime: [new Date(2000, 1, 1, 9, 0, 0)], //9点，实际值为早上1点，去掉8小时
      apps: {},
      show: false,
      showIntro: false,
      loadingsa: false,
      mainTemplate: null,
      config: {
        backgroundOpacity: 0.8,
        titleStyle: {
          textAlign: 'center',
          fontSize: '19px',
        },
        contentStyle: {
          textAlign: 'center',
          fontSize: '15px',
        },     

      },
      options: [
        {
          label: "我的资料",
          key: 'mydata',
        },
        {
          label: "退出登录",
          key: "exit",
        }
      ],     
    };
  },
  // computed: { 
  //   portrait: function () {
  //     let ret = this.$gv.contentURL + '/images/Avatar/';
  //     if (this.$store.state.UserL && this.$store.state.UserL.User_Portrait && this.$store.state.UserL.User_Portrait !== 'null') {
  //       if (this.$store.state.UserL.User_Portrait.indexOf('http') > -1) {
  //         ret = decodeURIComponent(this.$store.state.UserL.User_Portrait);
  //       } else {
  //         ret += this.$store.state.UserL.User_Portrait;
  //       }
  //     }
  //     else
  //       ret += 'default.png';
  //     return ret;
  //   }
  // },
  beforeMount() {
    this.$store.state.loadingBar = useLoadingBar();
    if (this.$store.state.UserL.Need_Change) {
      //加载mydata页
      this.$notify({
        type: "warning",
        title: this.$t('login.changePassword') + "!",
        message: "初次登录，请更改密码以解锁菜单功能",
      })
      this.$gf.OpenInTabs("我的资料", 'Account/MyData')
      this.$store.state.currentTab = "我的资料";
      this.ActiveTab("我的资料");
    } else {
      this.LoadMenu();
    }

  },
  mounted() {
      this.$gf.OpenInTabs("待办列表", '/YuanDa/ToDoListMGT');
  },

  methods: { 
    //#region下拉菜单
    handleSelect(key: string | number) {
      if (key == 'exit')
        this.Logout();
      else if (key == 'mydata')
        this.$gf.OpenInTabs("我的资料", 'Account/MyData')
    },

    renderDropdownIcon(option: DropdownOption) {
      const iconValue = `#icon-${option['key']}`

      return h('svg', { 'class': ['svg-icon'], 'aria-hidden': [true] }, [h('use', { 'xlink:href': [iconValue], 'fill': ['#333333'] }, '')])
    },
    //endregion
    //#region 菜单相关
    handleCollapse(ok) {
      this.collapsed = ok;
      // this.$nextTick(() => {
      if (this.$refs[this.$store.state.currentTab] && this.$refs[this.$store.state.currentTab][0] && this.$refs[this.$store.state.currentTab][0].handleParentClick) {
        this.$refs[this.$store.state.currentTab][0].handleParentClick(ok);
      }
      // });

    },
    renderMenuIcon(option: MenuOption) {
      const iconValue = option['MOD_PictureName']
      let icon=null;
      for (const [key, component] of Object.entries(ElementPlusIconsVue)){
        if(key=='Folder')
        icon=component;
        if(key==iconValue)
        return h(component)
      }
      return h(icon)
    },
    LoadMenu: function () {
      this.$axios.Post("menu", null,  this.$store.state.UserL.Role_ID).then((res) => {
        var o = res;
        // for (let i = 0; i < o.length; i++) {
        //   if (i < 3)
        //     this.defaultExpandKey.push(o[i].MOD_Code);
        // }
        this.menu = this.$gf.ListToTreeList(o, "MOD_Code", "MOD_ParentCode");
        this.collapsed = false;
        this.expandall = true;
        //  this.$emit("menuList",oo);
      });
    },
    //#endregion

    Logout() {
      this.$axios.Post("logout").then(() => {
        this.$store.state.UserL=null;
     this.$gf.DeleteCookie("UserL");

      });
    },
    //#region Tab相关

    CloseAllTabs: function () {
      this.$confirm("确认关闭所有页面吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.state.allTabs = [];
      });
    },
    OpenMenu(key, item) {
      this.$store.state.currentParentCode = item.Parent_Code
      this.$gf.OpenInTabs( item.MOD_Name, item.MOD_Url + item.MOD_File);//这里处理是为了确保原html的可以正常使用，后期可以去掉处理
    },
    handleClose(name) {
      if (this.$store.state.allTabs.length === 1) {
        this.$message.error("最后一个了");
        return;
      }

      this.$message.info("关掉 " + name);
      const index = this.$store.state.allTabs.findIndex((v) => name === v);
      this.$store.state.allTabs.splice(index, 1);

      if (this.$store.state.currentTab === name) {
        this.$store.state.currentTab = this.$store.state.allTabs[index].name;
      }
    },
    removeTab(targetName: string) {
      var aa = 0;
      this.$store.state.allTabs.forEach((tab, index) => {
        if (tab.name === targetName) {
          aa = index - 1;
          this.$store.state.allTabs.splice(index, 1);
        }
      });
      // localStorage.setItem("deal3_allTabs", JSON.stringify(this.$store.state.allTabs))

      if (aa < 0) aa = 0;
      if (this.$store.state.allTabs.length > 0 && this.$store.state.currentTab == targetName)
        this.$store.state.currentTab = this.$store.state.allTabs[aa].name;

    },

    //#endregion

  }, 
});
</script>
<style scoped>

.tabDiv :deep() .n-tabs .n-tabs-nav {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fff;
   z-index:1000;
}
        html, body {
            margin: 0;
            height: 100%;
            overflow: hidden;
            font-family: 微软雅黑;
            font-size: 13px;
        }
        .header {
            height: 60px;
            /* background-color: #eeeeee; */
  background: url("../assets/images/banner.jpg") round;
            padding: 0;
            border-bottom:1px dashed darkblue;
        }
  .logo {
            width: 180px;
            height: 50px;
            margin-left: 15px;
            float: left;
        }
  .headtext {
            font-size: 25px;
            font-weight: 400;
            color: #fff;
            padding: 15px;
            margin-left: 15px;
            padding-left: 0;
            margin-bottom: 5px;
            width: 150px;
            font-family: 微软雅黑;
            float: left;
            height: 20px;
            border-bottom: 1px solid white;
        }
.menu0 :deep() .n-menu-item {
  background-color: #b3b5b62d;
}

.menu0 :deep() .n-submenu-children .n-menu-item {
  border: none;
  background-color: transparent;
}

.menu1 :deep() .n-menu-item {
  background-color: #005fa6;
  border-bottom: 1px dashed white;
}

.menu1 :deep() .n-submenu-children .n-menu-item {
  background-color: #293b5a;
  border-bottom: 1px dashed white;
}

.menu1 :deep() .n-submenu {
  background-color: #005fa6;
}

.menu1 :deep() .n-submenu-children {
  background-color: #293b5a;
}

.menu1 :deep() .n-menu-item-content-header {
  /* background-color:#a60045; */
  color: rgb(56, 55, 55);

}

.menu1 :deep() .n-menu-item-content__icon {
  /* background-color:#a60045; */
  color: #fff;

}

.menu1 :deep() .n-menu-item.n-menu-item--selected {
  color: rgb(253, 251, 125);
}

.ASide :deep() .n-layout-sider-scroll-container {
  margin: 20px 0px;
  height: calc(100vh - 100px);
}

.ASide :deep() .n-layout-toggle-button {
  background-color: #2523222c;
  z-index: 999;
}
</style>
<style>
.menu1 {
  border-radius: 10px;

}

.ASide {
  background-color: #005fa6;
  border-radius: 10px;
  margin-top: 10px;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: 微软雅黑;
  font-size: 13px;
}



.t1 {
  color: #ca5235;
  font-size: 15px;
}

.t2 {
  color: #f0f0f0;
  font-size: 12px;
}

.tabs {
  height: 30px;
}

.iframe {
  width: 100%;
  height: calc(100vh - 60px);
  border: none;
  margin: 0px;
  padding: 0px;
}

.tabs1 {
  height: 90%;
  width: 100%;
  background-color: greenyellow;
}

.tabBtns {
  position: absolute;
 padding-left:15px;
  top: -5px;
  right: 0px;
  font-size: 30px;
  width:60px;
  background-color: #fff;
   z-index:1000;
}

.icon {
  margin: -5px -10px;
  width: 24px;
  height: 24px;
}

.btn1 {
  background-color: transparent;
  border: 0px;
  text-align: center;
  width: 50px;
  height: 50px;
  color: #fff;
}

.svg-icon {
  display: inline-flex;
  align-self: center;
  position: relative;
  height: 1em;
  width: 1em;
}

.svg-icon svg {
  height: 1em;
  width: 1em;
}

.svg-icon.svg-baseline svg {
  bottom: -0.125em;
  position: absolute;
}

.span-a {
  width: 6px;
  height: 6px;
  display: inline-block;
}

.green_c {
  background: rgb(3, 248, 3)
}

.red_c {
  background: rgb(255, 0, 0)
}
</style>
