import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Home = _resolveComponent("Home")!
  const _component_Login = _resolveComponent("Login")!
  const _component_n_loading_bar_provider = _resolveComponent("n-loading-bar-provider")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, null, {
    default: _withCtx(() => [
      _createVNode(_component_n_loading_bar_provider, null, {
        default: _withCtx(() => [
          (this.$store.state.UserL && this.$store.state.UserL.Role_ID>0)
            ? (_openBlock(), _createBlock(_component_Home, {
                key: 0,
                ref: "home"
              }, null, 512))
            : (_openBlock(), _createBlock(_component_Login, { key: 1 }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}